;(function (global) {
	global.dateDropperSetup.languages['uk'] = {
		name: '\u0443\u043a\u0440\u0430\u0457\u043d\u0441\u044c\u043a\u0430 \u043c\u043e\u0432\u0430',
		m: {
			s: [
				'\u0441\u0456\u0447\u0435\u043d\u044c',
				'\u043b\u044e\u0442\u0438\u0439',
				'\u0431\u0435\u0440\u0435\u0437\u0435\u043d\u044c',
				'\u043a\u0432\u0456\u0442\u0435\u043d\u044c',
				'\u0442\u0440\u0430\u0432\u0435\u043d\u044c',
				'\u0447\u0435\u0440\u0432\u0435\u043d\u044c',
				'\u043b\u0438\u043f\u0435\u043d\u044c',
				'\u0441\u0435\u0440\u043f\u0435\u043d\u044c',
				'\u0432\u0435\u0440\u0435\u0441\u0435\u043d\u044c',
				'\u0436\u043e\u0432\u0442\u0435\u043d\u044c',
				'\u043b\u0438\u0441\u0442\u043e\u043f\u0430\u0434',
				'\u0433\u0440\u0443\u0434\u0435\u043d\u044c',
			],
			f: [
				'\u0441\u0456\u0447\u0435\u043d\u044c',
				'\u043b\u044e\u0442\u0438\u0439',
				'\u0431\u0435\u0440\u0435\u0437\u0435\u043d\u044c',
				'\u043a\u0432\u0456\u0442\u0435\u043d\u044c',
				'\u0442\u0440\u0430\u0432\u0435\u043d\u044c',
				'\u0447\u0435\u0440\u0432\u0435\u043d\u044c',
				'\u043b\u0438\u043f\u0435\u043d\u044c',
				'\u0441\u0435\u0440\u043f\u0435\u043d\u044c',
				'\u0432\u0435\u0440\u0435\u0441\u0435\u043d\u044c',
				'\u0436\u043e\u0432\u0442\u0435\u043d\u044c',
				'\u043b\u0438\u0441\u0442\u043e\u043f\u0430\u0434',
				'\u0433\u0440\u0443\u0434\u0435\u043d\u044c',
			],
		},
		w: {
			s: [
				'\u043d',
				'\u043f',
				'\u0432',
				'\u0441',
				'\u0447',
				'\u043f',
				'\u0441',
			],
			f: [
				'\u043d\u0435\u0434\u0456\u043b\u044f',
				'\u043f\u043e\u043d\u0435\u0434\u0456\u043b\u043e\u043a',
				'\u0432\u0456\u0432\u0442\u043e\u0440\u043e\u043a',
				'\u0441\u0435\u0440\u0435\u0434\u0430',
				'\u0447\u0435\u0442\u0432\u0435\u0440',
				"\u043f'\u044f\u0442\u043d\u0438\u0446\u044f",
				'\u0441\u0443\u0431\u043e\u0442\u0430',
			],
		},
	}
})((globalThis.CodeCrumbs = globalThis.CodeCrumbs || {}))
